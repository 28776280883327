<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <b-card
        v-if="channel !== null"
        no-body
        class="mb-2"
    >

      <h2 class="m-2">ویرایش نام کانال</h2>

      <div class="m-2">

        <!-- Table Top -->

        <b-row>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="channel.title"
                  class="d-inline-block mr-1"
              />
            </div>
          </b-col>

          <b-col md="2" class="mb-1">
            <b-button
                @click="updateChannel"
                variant="success"
            >
              <span class="text-nowrap">ویرایش</span>
            </b-button>
          </b-col>
        </b-row>

      </div>
    </b-card>

    <div v-if="channelMembers !== null">
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-2"
      >

        <h2 class="m-2">لیست اعضا کانال</h2>

        <div class="m-2">

          <!-- Table Top -->
<!--          <b-row>-->

<!--            <b-col-->
<!--                cols="12"-->
<!--                md="3"-->
<!--                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--            >-->
<!--              <label>نمایش</label>-->
<!--              <v-select-->
<!--                  v-model="perPage"-->
<!--                  :options="perPageOptions"-->
<!--                  :clearable="false"-->
<!--                  class="per-page-selector d-inline-block mx-50"-->
<!--              />-->
<!--              <label>تعداد</label>-->
<!--            </b-col>-->

<!--            <b-col-->
<!--                cols="12"-->
<!--                md="3"-->
<!--                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--            >-->
<!--              <label>نمایش</label>-->
<!--              <v-select-->
<!--                  v-model="perState"-->
<!--                  :options="perStateOptions"-->
<!--                  :reduce="val => val.value"-->
<!--                  :clearable="false"-->
<!--                  class="per-page-selector d-inline-block mx-50 w-50"-->
<!--              />-->
<!--              <label>وضعیت</label>-->
<!--            </b-col>-->

<!--            &lt;!&ndash; Search &ndash;&gt;-->
<!--            <b-col-->
<!--                cols="12"-->
<!--                md="6"-->
<!--            >-->
<!--              <div class="d-flex align-items-center justify-content-end">-->
<!--                <b-form-input-->
<!--                    v-model="searchQuery"-->
<!--                    class="d-inline-block mr-1"-->
<!--                    placeholder="جستجو..."-->
<!--                />-->
<!--              </div>-->
<!--            </b-col>-->
<!--          </b-row>-->

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="channelMembers"
            responsive
            :fields="myTableColumns2"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/${data.item.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name + ' ' + data.item.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>

          </template>

          <!-- Column: type -->
          <template #cell(type)="data">
            <b-badge
                pill
                class="text-capitalize"
                :variant="``"
            >
              {{ data.item.type }}
            </b-badge>
          </template>


          <!-- Column: state -->
          <template #cell(user.state)="data">
            <b-badge
                v-if="data.item.state === 1"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              وارد شده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 2"
                pill
                class="text-capitalize"
                :variant="`warning`"
            >
              فالو نکرده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 3"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              آیدی اشتباه
            </b-badge>
          </template>

          <!-- Column: edit -->
          <template #cell(delete)="data">
            <div class="text-danger cursor-pointer" @click="deleteMember(data.item.userId)" >
              <feather-icon icon="TrashIcon" size="20"/>
            </div>
          </template>

        </b-table>
<!--        <div class="mx-2 mb-2">-->
<!--          <b-row>-->

<!--            <b-col-->
<!--                cols="12"-->
<!--                sm="6"-->
<!--                class="d-flex align-items-center justify-content-center justify-content-sm-start"-->
<!--            >-->
<!--            </b-col>-->
<!--            &lt;!&ndash; Pagination &ndash;&gt;-->
<!--            <b-col-->
<!--                cols="12"-->
<!--                sm="6"-->
<!--                class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
<!--            >-->

<!--              <b-pagination-->
<!--                  v-model="currentPage"-->
<!--                  :total-rows="totalCounts"-->
<!--                  :per-page="perPage"-->
<!--                  first-number-->
<!--                  last-number-->
<!--                  class="mb-0 mt-1 mt-sm-0"-->
<!--                  prev-class="prev-item"-->
<!--                  next-class="next-item"-->
<!--              >-->
<!--                <template #prev-text>-->
<!--                  <feather-icon style="transform: rotate(180deg)"-->
<!--                                icon="ChevronLeftIcon"-->
<!--                                size="18"-->
<!--                  />-->
<!--                </template>-->
<!--                <template #next-text>-->
<!--                  <feather-icon-->
<!--                      icon="ChevronRightIcon"-->
<!--                      size="18"-->
<!--                  />-->
<!--                </template>-->
<!--              </b-pagination>-->

<!--            </b-col>-->

<!--          </b-row>-->
<!--        </div>-->
      </b-card>
    </div>

    <div v-if="users !== null">
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <h2 class="m-2">افزودن عضو</h2>

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>

            <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perState"
                  :options="perStateOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-50"
              />
              <label>وضعیت</label>
            </b-col>

            <!-- Search -->
            <b-col
                cols="12"
                md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="جستجو..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="users"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                    size="45"
                    :src="`https://api.maxterms.com/${data.item.selfieFileData}`"
                    :variant="`success`"
                    :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                />
              </template>
              <b-link
                  :to="{ name: 'apps-users-edit', params: { id: data.item.userId } }"
                  class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name + ' ' + data.item.familyName }}
              </b-link>
              <small class="text-muted">@{{ data.item.userId }}</small>
            </b-media>

          </template>

          <!-- Column: type -->
          <template #cell(type)="data">
            <b-badge
                pill
                class="text-capitalize"
                :variant="``"
            >
              {{ data.item.type }}
            </b-badge>
          </template>


          <!-- Column: state -->
          <template #cell(state)="data">
            <b-badge
                v-if="data.item.state === 1"
                pill
                class="text-capitalize"
                :variant="`success`"
            >
              وارد شده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 2"
                pill
                class="text-capitalize"
                :variant="`warning`"
            >
              فالو نکرده
            </b-badge>
            <b-badge
                v-else-if="data.item.state === 3"
                pill
                class="text-capitalize"
                :variant="`danger`"
            >
              آیدی اشتباه
            </b-badge>
          </template>

          <!-- Column: edit -->
          <template #cell(select)="data">
            <div class="text-primary cursor-pointer" @click="addMember(data.item.userId)" >
              <feather-icon icon="UserPlusIcon" size="20"/>
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {UserProductBlockGetAllUserRequest} from "@/libs/Api/UserProductBlock";
import {
  ChannelGetRequest,
  ChannelUserCreateRequest,
  ChannelUpdateRequest,
  ChannelUserGetUserByChannelIdRequest,
  ChannelUserDeleteUserFromChannelRequest
} from "@/libs/Api/Channel";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {UsersGetAllRequest} from "@/libs/Api/Users";

export default {
  name:'ChannelEdit',
  title:"ویرایش کانال - پنل ادمین مکس ",
  data(){
    return{
      channel:null,
      users:null,
      channelMembers:null,
      overlay:false,
      channelId:this.$route.params.id,
      currentPage:1,
      totalCounts:null,
      perPage:10,
      perState:'',
      perStateOptions:[
        {label:'همه',value:''},
        {label:'وارد شده',value:1},
        {label:'فالو نکرده',value:2},
        {label:'آیدی اشتباه',value:3}
      ],
      perPageOptions:[10, 20, 30 , 40 , 50],
      myTableColumns : [
        { key: 'user' ,label :'نام کامل'},
        { key: 'instagramId' ,label :'اینستاگرام'},
        { key: 'nationalId' ,label :'کد ملی'},
        { key: 'mobile',label: 'موبایل'},
        { key: 'state' ,label :'وضعیت'},
        { key: 'select',label:'انتخاب کاربر'},
      ],
      myTableColumns2:[
        { key: 'user' ,label :'نام کامل'},
        { key: 'instagramId' ,label :'اینستاگرام'},
        { key: 'nationalId' ,label :'کد ملی'},
        { key: 'mobile',label: 'موبایل'},
        { key: 'state' ,label :'وضعیت'},
        { key: 'delete',label:'حذف کاربر'},
      ],
      searchQuery:'',
      isAddNewUserSidebarActive:false,
    }
  },
  async created() {
    await this.getChannel();
    await this.getChannelMembers();
    await this.getUsers(this.perPage,this.currentPage,this.searchQuery,this.perState);
  },
  methods:{
    refreshData(){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery,this.perState)
    },
    async updateChannel(){
      let _this = this;
      _this.overlay = true;

      let channelUpdateRequest = new ChannelUpdateRequest(_this);
      channelUpdateRequest.setParams(_this.channel);
      await channelUpdateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `ویرایش انجام شد.`,
          },
        })
        _this.getChannel();
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async getChannelMembers(){
      let _this = this;
      _this.overlay = true;

      let data = {
        channelId :this.channelId,
        pageNumber:1,
        count:10,
        search:'',
        state:''
      }

      let channelUserGetUserByChannelIdRequest = new ChannelUserGetUserByChannelIdRequest(_this);
      channelUserGetUserByChannelIdRequest.setParams(data);
      await channelUserGetUserByChannelIdRequest.fetch(function (content){
        _this.overlay = false;
        _this.channelMembers = content.users;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async getChannel(){
      let _this = this;
      _this.overlay = true;

      let data = {
        channelId :this.channelId,
      }

      let channelGetRequest = new ChannelGetRequest(_this);
      channelGetRequest.setParams(data);
      await channelGetRequest.fetch(function (content){
        _this.overlay = false;
        _this.channel = content;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async getUsers(count,pageNumber,search,state){
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber:pageNumber,
        count:count,
        search:search,
        state:state
      }

      let usersGetAllRequest = new UsersGetAllRequest(_this);
      usersGetAllRequest.setParams(data);
      await usersGetAllRequest.fetch(function (content){
        _this.overlay = false;
        _this.users = content.users;
        _this.totalCounts = content.usersCount;
      },function (error){
        _this.overlay = false;
        console.log(error);
      })
    },
    async addMember(param){
      let _this = this;
      _this.overlay = true;

      let data = {
        isDeleted: false,
        channelUserId: 0,
        userId: param,
        channelId: _this.channelId,
        createDate: new Date(),
      }

      let channelUserCreateRequest = new ChannelUserCreateRequest(_this);
      channelUserCreateRequest.setParams(data);
      await channelUserCreateRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کاربر اضافه شد.`,
          },
        })
        _this.getChannelMembers()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async deleteMember(param){
      let _this = this;
      _this.overlay = true;

      let data = {
        userId:param,
        channelId:_this.channelId
      }

      let channelUserDeleteUserFromChannelRequest = new ChannelUserDeleteUserFromChannelRequest(_this);
      channelUserDeleteUserFromChannelRequest.setParams(data);
      await channelUserDeleteUserFromChannelRequest.fetch(function (content){
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `کاربر حذف شد.`,
          },
        })
        _this.getChannelMembers()
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
  },
  watch:{
    perPage:function (nv,ov){
      this.getUsers(nv,this.currentPage,this.searchQuery,this.perState);
    },
    currentPage:function (nv,ov) {
      this.getUsers(this.perPage,nv,this.searchQuery,this.perState);
    },
    searchQuery:function (nv,ov){
      this.getUsers(this.perPage,this.currentPage,nv,this.perState);
    },
    perState:function (nv,ov){
      this.getUsers(this.perPage,this.currentPage,this.searchQuery,nv);
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BInputGroup, BInputGroupAppend,

    vSelect,
  },
}
</script>

<style lang="scss" scoped>

</style>